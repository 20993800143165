import axios from 'axios';
import config from './config';
import authenticationService from './AuthenticationService';

const client = axios.create({
  baseURL: config.get('SEARCH_API_URL')
});

export default {
  saveMemberSearch: async (memberId, searchString, searchName) => {
    let response = await client.post('/inventory/membersearchsave', {
      memberId: memberId,
      url: searchString,
      name: searchName
    }, await authenticationService.getRequestOptions(null, true));
    return response.data;
  },
  updateMemberSearch: async (savedSearch) => {
    let response = await client.post('/member/search', savedSearch);
    return response.data;
  },
  deleteMemberSearch: async (memberId, searchString) => {
    let response = await client.post('/inventory/membersearchdelete', {
      memberId: memberId,
      url: searchString
    });
    return response.data;
  },
  getSavedSearchByMemberId: async (memberId) => {
    let response = await client.get(`/inventory/membersavedsearch/${memberId}`);
    return response.data;
  }
}
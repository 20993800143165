const historyService = {
  previousUrl: '',
  currentUrl: '',
  addUrl: (router, url) => {
    if (!historyService.currentUrl || !historyService.currentUrl.startsWith || !historyService.currentUrl.startsWith(url)) {
      // This means we ignore stripping the "source" query parameter from the URL
      historyService.previousUrl = historyService.currentUrl;
    }
    historyService.currentUrl = url;
  }
};

if (typeof window !== 'undefined') {
  historyService.currentUrl = document.location.pathname
}

export default historyService;
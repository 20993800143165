import Auth from '@aws-amplify/auth';
import { v4 as uuidv4 } from 'uuid';

const getCurrentUserAttributes = async () => {
  try {
    const currentSession = await Auth.currentSession();
    return currentSession.getIdToken().payload;
  }
  catch (error) {
    return {};
  }
}

const getDeviceId = () => {
  let deviceId = '';
  try {
    const localStorageKey = 'my105-device-id';
    deviceId = localStorage.getItem(localStorageKey);
    if (!deviceId) {
      deviceId = uuidv4();
      localStorage.setItem(localStorageKey, deviceId);
    }
  }
  catch { }
  return deviceId;
}

export default {
  isAdmin: async () => {
    const userAttributes = await getCurrentUserAttributes();
    return userAttributes['custom:isAdmin'] === 'true';
  },
  getUserAttributes: async () => {
    return await getCurrentUserAttributes();
  },
  getRequestOptions: async (authenticationToken, includeDeviceId) => {
    if (!authenticationToken) {
      try {
        authenticationToken = (await Auth.currentSession()).idToken.jwtToken
      }
      catch { }
    }
    const result = {
      headers: {
        Authorization: authenticationToken
      }
    };
    if (includeDeviceId) {
      result.headers['my105-device-id'] = getDeviceId();
    }
    return result;
  }
}
import axios from 'axios';
import authenticationService from './AuthenticationService';
import config from './config';

const client = axios.create({
  baseURL: config.get('SELL_API_URL')
});

export default {
  subscribeEmail: async email => {
    let response = await client.post('/subscribe/email', {
      email: email
    });
    return response.data;
  },
  create: async (userId, email, issubscribed, authenticationToken = null) => {
    let response = await client.post('/users/event', {
      username: userId,
      email: email,
      issubscribed: issubscribed
    }, await authenticationService.getRequestOptions(authenticationToken, false));
    return response.data;
  }
}
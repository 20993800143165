
import 'semantic-ui-css/components/reset.min.css';
import 'semantic-ui-css/components/site.min.css';
import 'semantic-ui-css/components/transition.min.css';

import 'semantic-ui-css/components/accordion.min.css';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/breadcrumb.min.css';
import 'semantic-ui-css/components/checkbox.min.css';
import 'semantic-ui-css/components/container.min.css';
import 'semantic-ui-css/components/dimmer.min.css';
import 'semantic-ui-css/components/divider.min.css';
import 'semantic-ui-css/components/dropdown.min.css';
import 'semantic-ui-css/components/form.min.css';
import 'semantic-ui-css/components/grid.min.css';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/input.min.css';
import 'semantic-ui-css/components/label.min.css';
import 'semantic-ui-css/components/list.min.css';
import 'semantic-ui-css/components/loader.min.css';
import 'semantic-ui-css/components/menu.min.css';
import 'semantic-ui-css/components/message.min.css';
import 'semantic-ui-css/components/modal.min.css';
import 'semantic-ui-css/components/popup.min.css';
import 'semantic-ui-css/components/segment.min.css';
import 'semantic-ui-css/components/progress.min.css';
import 'semantic-ui-css/components/table.min.css';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';

import Amplify from '@aws-amplify/core';
import Head from 'next/head';
import NextNprogress from 'nextjs-progressbar';
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import Script from 'next/script'

import Header from '../components/furniture/Header'
import Footer from '../components/furniture/Footer'

import { ProvideAuth } from '../hooks/use-auth';
import config from '../services/config';
import layout from '../services/layout';
import { loadStripe } from '@stripe/stripe-js';

datadogLogs.init({
  clientToken: config.get('DATADOG_CLIENT_TOKEN'),
  env: config.get('DATADOG_ENV'),
  service: 'my105-web',
  version: process.env.NEXT_PUBLIC_NEXTJS_BUILD_ID,
  forwardConsoleLogs: ['warn', 'error'],
  sampleRate: 100,
});

const ddRumClientToken = config.get('DATADOG_RUM_CLIENT_TOKEN');
if (ddRumClientToken) {
  datadogRum.init({
    applicationId: '132b7c08-6c8a-4969-9c0b-16a89b6a49e9',
    clientToken: ddRumClientToken,
    site: 'datadoghq.com',
    service: 'my105-web',
    env: config.get('DATADOG_ENV'),
    version: process.env.NEXT_PUBLIC_NEXTJS_BUILD_ID,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 10,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  });
}
datadogRum.startSessionReplayRecording();

var amplifyConfig = {
  "aws_project_region": "ap-southeast-2",
  "aws_cognito_region": "ap-southeast-2",
  "aws_user_pools_id": config.get('COGNITO_USER_POOL_ID'),
  "aws_user_pools_web_client_id": config.get('COGNITO_USER_POOL_CLIENT_ID'),
  "oauth": {
    "domain": config.get('COGNITO_USER_POOL_DOMAIN'),
    "scope": [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin"
    ],
    "redirectSignIn": config.get('COGNITO_REDIRECT_URL'),
    "redirectSignOut": config.get('COGNITO_REDIRECT_URL'),
    "responseType": "code"
  },
  "federationTarget": "COGNITO_USER_POOLS",
  // We're using USER_PASSWORD_AUTH so we can migrate users from the classic site.
  // When this is no longer required we should switch it back to USER_SRP_AUTH
  // which is the default so simply removing this line below will do it
  // Reference:  https://docs.amplify.aws/lib/auth/switch-auth/q/platform/js
  "authenticationFlowType": "USER_PASSWORD_AUTH"
};

Amplify.configure(amplifyConfig);

loadStripe(config.get('STRIPE_PUBLISHABLE_KEY'));

// AJB: do I need to override both _app and _document?
function MyApp({ Component, pageProps }) {
  return <>
    <Head>
      <meta key="viewport" name="viewport" content="initial-scale=1.0, width=device-width" />
    </Head>
    <Script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" />
    <NextNprogress
      color={layout.colors.primary}
      startPosition={0.3}
      stopDelayMs={200}
      height={8}
      showOnShallow={false}
      options={{
        showSpinner: false
      }}
    />
    <ProvideAuth>
      {/* This is used for monitoring that SSR is enabled (there was a weird issue that disabled it) */}
      <div style={{ display: 'none' }}>SSR Enabled</div>
      <Header />
      <Component {...pageProps} />
      <Footer />
    </ProvideAuth>
  </>
}

export default MyApp

import axios from 'axios';
import config from './config';
import authenticationService from './AuthenticationService';

const client = axios.create({
  baseURL: config.get('SEARCH_API_URL')
});

export default {
  watchAd: async (memberId, adId) => {
    let response = await client.post('/inventory/memberadsave', {
      memberId: memberId,
      adId: adId
    }, await authenticationService.getRequestOptions(null, true));
    return response.data;
  },
  unwatchAd: async (memberId, adId) => {
    let response = await client.post('/inventory/memberaddelete', {
      memberId: memberId,
      adId: adId
    }, await authenticationService.getRequestOptions(null, true));
    return response.data;
  },
  unwatchAds: async (memberId, adIds) => {
    let response = await client.post('/inventory/memberadsdelete', {
      memberId: memberId,
      adIds: adIds
    }, await authenticationService.getRequestOptions(null, true));
    return response.data;
  },
  getWatchlist: async (memberId) => {
    let response = await client.get(`/inventory/membersave/${memberId}`,
      await authenticationService.getRequestOptions(null, true));
    return response.data;
  }
}